<template>
  <CRow class="settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              <span>{{$t('admin.Default_email')}}{{!emailContentLoading && email.description ? ': ' + email.description : ''}}{{!emailContentLoading && email.environment_name ? ' - ' + email.environment_name : ''}}</span>
            </CCol>
            <CCol v-if="emailContentLoading === false" cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-flex justify-content-lg-end">
                <CButton v-if="templates.length > 0" 
                         @click="selectTemplateView = true" 
                         color="secondary" 
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope mr-1"></i>{{$t('common.Load_email_template')}}</span>
                </CButton>
                <CButton v-if="email.json"
                         @click="openSidebarRight('save_email_template', { template_type: 'email', template_source_id_external:  email.platform_event_type_id_external, product_tag: email.product_tag })" 
                         color="secondary"
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope-circle-check mr-2"></i>{{$t('common.Save_as_email_template')}}</span>
                </CButton>                
                <CButton v-if="emailEditorReady === true" @click="updateDefaultEmailContent(email);" color="primary" class="m-0 btn_small">
                  <span><i class="fas fa-check mr-1"/>{{$t('admin.Update_default_email_content')}}</span>
                </CButton>                
              </div>
            </CCol>            
          </CRow>
        </CCardHeader>
        <CCardBody class="email">
          <CRow v-if="emailContentLoading === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="emailContentLoading === false">
            <CCol cols="12" lg="12" class="pt-0 pb-0">

              <transition name="slide-big">
                <CRow v-if="selectTemplateView" class="mb-2_5">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <span>{{$t('common.Pick_mail_template')}}</span>
                  </CCol>
                  <CCol cols="3" lg="3" class="pb-0">
                    <navigationCard @click.native="setEmailTemplate(email.tag, email.json)" path="" :cardTitle="$t('common.Current_template')" :cardDescription="$t('common.Keep_using_current_template')" :cardIcon="null" class="h-100"/>
                  </CCol>                                     
                  <CCol v-for="template in templates" v-bind:key="template.platform_event_email_template_id_external" cols="3" lg="3" class="pb-0">
                    <navigationCard @click.native="setEmailTemplate(email.tag, template.json)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null" class="h-100"/>
                  </CCol>
                </CRow>
              </transition>

              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="email.subject" required was-validated/>
                </CCol>
              </CRow>
              <CRow>
                <CCol cols="12" lg="12" class="pb-0">
                  <label class="mt-0 mb-2">{{$t('communications.email_content')}}</label>
                  <div v-if="email.email_content_tags">
                    <span>{{ $t('communications.available_tags') }} {{email.email_content_tags}}</span>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol cols="12" lg="12" class="pb-0">
                  <EmailEditor :ref="'emailEditor_' + email.tag"
                               :appearance="emailEditorConfig.appearance"
                               :min-height="emailEditorConfig.minHeight"
                               :project-id="emailEditorConfig.projectId"
                               :locale="emailEditorConfig.locale"
                               :tools="emailEditorConfig.tools"
                               :options="emailEditorConfig.options"
                               v-on:ready="editorReady(email.tag)">
                  </EmailEditor>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>             
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import { EmailEditor } from 'vue-email-editor';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import navigationCard from '@/components/common/navigationCard';

export default {
  name: 'Moment',
  components: {
    EmailEditor,
    loadingSpinner,
    navigationCard
  },
  watch: { 
    '$route.params.id': {
      handler() {
        this.emailContentIdExternal = this.$route.params.id;
        // Get the default email content
        this.getDefaultEmailContent();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      emailContentIdExternal: null,
      apiBaseUrl: null,
      clientToken: null,
      email: {},
      templates: [],
      uploadingFiles: false,   
      emailContentLoading: false,
      emailContentLoaded: false,
      selectTemplateView: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935, //'50935' = Harry HR
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
            ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            breaking_news: {
              name: "Breaking News",
              value: "{$breaking_news}"
            },                      
            team: {
              name: "Employee Team",
              value: "{$team}"
            },
            department: {
              name: "Employee Department",
              value: "{$department}"
            },            
            points: {
              name: "Received Points",
              value: "{$points}"
            },
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        },
        // fonts: {
        //   showDefaultFonts: false,
        //   customFonts: [{
        //     label: "Quicksand",
        //     value: "'Quicksand', sans-serif",
        //     url: "https://fonts.googleapis.com/css?family=Quicksand:400,500,700"
        //   }]
        // }               
      },
      emailEditorReady: false
    }
  },
  methods: {
    getDefaultEmailContent() {
      // Start the loader
      if(!this.emailContentLoaded) this.emailContentLoading = true;
      // Get the default email content
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default/email/' + this.emailContentIdExternal)
      .then(res => {
        this.email = res.data.data;
        // Stop the loader
        this.emailContentLoading = false;
        // Update the emailContentLoaded value
        this.emailContentLoaded = true;
        // Set the emailEventTag variable
        let emailEventTag = this.email.tag;
        // Check if the email content contains a valid JSON
        if(this.email.json) {
          // If so, set he mailDesign in the editor
          let mailDesign = JSON.parse(this.email.json);
          // Wait 2000ms and load the mailDesign
          setTimeout(function() {
            if(this.$refs['emailEditor_' + emailEventTag] && this.$refs['emailEditor_' + emailEventTag].editor) {
              this.$refs['emailEditor_' + emailEventTag].editor.loadDesign(mailDesign);
            }
          }.bind(this), 2000);
        }              
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    updateDefaultEmailContent(emailContentData) {
      let params = {};
      params.emailContentData = emailContentData;
      if(!params.emailContentData.json) params.emailContentData.json = null;
      if(!params.emailContentData.html) params.emailContentData.html = null;

      if(this.$refs !== undefined && this.$refs['emailEditor_' + emailContentData.tag] !== undefined ) {
        this.$refs['emailEditor_' + emailContentData.tag].editor.saveDesign((design) => {
          params.emailContentData.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_' + emailContentData.tag].editor.exportHtml((data) => {
          params.emailContentData.html = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the params
      setTimeout(function() {
        axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default/email/' + this.emailContentIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('admin.Default_email_content_updated'), type: 'is-success', duration: 2000 });
          // Update the email content
          this.getDefaultEmailContent();                     
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      }.bind(this), 500);      
    },
    getEmailTemplates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/list')
      .then(res => {      
        this.templates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });              
    },
    setEmailTemplate(emailEventTag, emailTemplateData) {
      // Update selectTemplateView value to close the view
      this.selectTemplateView = false;
      // Set the mailDesign variable
      let mailDesign = JSON.parse(emailTemplateData);
      // Wait 1000ms and load the mailDesign
      setTimeout(function(){          
        this.$refs['emailEditor_' + emailEventTag].editor.loadDesign(mailDesign);
      }.bind(this), 1000);
    },
    editorReady(emailEventTag) {
      this.emailEditorReady = true;
            
      if(emailEventTag) {
        this.$refs['emailEditor_' + emailEventTag].editor.setBodyValues({
          backgroundColor: "#F6F6F6",
          textColor: '#4A4A4A',
          contentAlignment: 'center',
          links: {
            color: '#4A4A4A',
            underline: true
          }
        });
      }
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getEmailTemplates();
  }
}
</script>
